// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as ID from "../../../libs/ID.res.js";
import * as Url from "../../../utils/Url.res.js";
import * as $$Date from "../../../libs/Date.res.js";
import * as Link from "../../../styleguide/components/Link/Link.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as Table from "../../../styleguide/components/Table/Table.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as Toggle from "../../../styleguide/forms/Toggle/Toggle.res.js";
import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Spinner from "../../../styleguide/components/Spinner/Spinner.res.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Dropdown from "../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as IconMenu from "../../../styleguide/icons/IconMenu.res.js";
import * as TableRow from "../../../styleguide/components/Table/TableRow.res.js";
import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as TableBody from "../../../styleguide/components/Table/TableBody.res.js";
import * as TableCell from "../../../styleguide/components/Table/TableCell.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as IconSorting from "../../../styleguide/icons/IconSorting.res.js";
import * as SearchField from "../../../styleguide/forms/SearchField/SearchField.res.js";
import * as TableHeader from "../../../styleguide/components/Table/TableHeader.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as Routes_BlogPost from "../../../routes/common/Routes_BlogPost.res.js";
import * as TableHeaderCell from "../../../styleguide/components/Table/TableHeaderCell.res.js";
import * as DashboardIndexCss from "../../../styleguide/dashboard/components/views/index/DashboardIndexCss.res.js";
import * as DashboardTableCss from "../../../styleguide/dashboard/components/table/DashboardTableCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardFilterCss from "../../../styleguide/dashboard/components/filters/DashboardFilterCss.res.js";
import * as Api_BlogPostCategory from "../../../api/blog-post-categories/Api_BlogPostCategory.res.js";
import * as BlogPostCategoryNavbar from "../navbar/BlogPostCategoryNavbar.res.js";
import * as Routes_BlogPostCategory from "../../../routes/common/Routes_BlogPostCategory.res.js";
import * as BlogPostCategoriesOptions from "./BlogPostCategoriesOptions.res.js";
import * as DashboardDeleteConfirmationModalCss from "../../../styleguide/dashboard/components/modals/deleteConfirmation/DashboardDeleteConfirmationModalCss.res.js";

var initialState_sortBy = {
  NAME: "Title",
  VAL: "Asc"
};

var initialState_entityUpdates = ID.$$Map.make();

var initialState = {
  status: "FetchingBlogPostCategories",
  currentPage: 1,
  totalPages: 1,
  sortBy: initialState_sortBy,
  search: "",
  entityUpdates: initialState_entityUpdates,
  openDestroyConfirm: false,
  currentCategoryId: undefined
};

function BlogPostCategoriesDashboard$default(props) {
  var container = React.useRef(null);
  var searchBlogPostCategories = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchBlogPostCategories" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              var match = state.search;
                              var tmp = match === "" ? undefined : state.search;
                              $$Promise.wait(Api_BlogPostCategory.dashboard(state.currentPage, tmp, state.sortBy), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedBlogPostCategoriesFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "BlogPostCategoriesDashboard",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "BlogPostCategoriesDashboard.default"
                                          }, "BlogPostCategoriesDashboard::FetchBlogPostCategories::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailBlogPostCategoriesFetch");
                                    }));
                            })
                        };
              case "FailBlogPostCategoriesFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "FailedBlogPostCategoriesFetch",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            entityUpdates: state.entityUpdates,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentCategoryId: state.currentCategoryId
                          }
                        };
              case "SortByTitle" :
                  var match = state.status;
                  if (typeof match !== "object" && match === "FetchingBlogPostCategories") {
                    return "NoUpdate";
                  }
                  var match$1 = state.sortBy;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingBlogPostCategories",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: typeof match$1 === "object" && match$1.NAME === "Title" && match$1.VAL !== "Desc" ? ({
                                  NAME: "Title",
                                  VAL: "Desc"
                                }) : ({
                                  NAME: "Title",
                                  VAL: "Asc"
                                }),
                            search: state.search,
                            entityUpdates: state.entityUpdates,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentCategoryId: state.currentCategoryId
                          },
                          _1: (function (param) {
                              param.dispatch("FetchBlogPostCategories");
                            })
                        };
              case "SortByCreatedAt" :
                  var match$2 = state.status;
                  if (typeof match$2 !== "object" && match$2 === "FetchingBlogPostCategories") {
                    return "NoUpdate";
                  }
                  var match$3 = state.sortBy;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingBlogPostCategories",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: typeof match$3 === "object" && match$3.NAME === "CreatedAt" && match$3.VAL !== "Desc" ? ({
                                  NAME: "CreatedAt",
                                  VAL: "Desc"
                                }) : ({
                                  NAME: "CreatedAt",
                                  VAL: "Asc"
                                }),
                            search: state.search,
                            entityUpdates: state.entityUpdates,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentCategoryId: state.currentCategoryId
                          },
                          _1: (function (param) {
                              param.dispatch("FetchBlogPostCategories");
                            })
                        };
              case "SortByUpdatedAt" :
                  var match$4 = state.status;
                  if (typeof match$4 !== "object" && match$4 === "FetchingBlogPostCategories") {
                    return "NoUpdate";
                  }
                  var match$5 = state.sortBy;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingBlogPostCategories",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: typeof match$5 === "object" && match$5.NAME === "UpdatedAt" && match$5.VAL !== "Desc" ? ({
                                  NAME: "UpdatedAt",
                                  VAL: "Desc"
                                }) : ({
                                  NAME: "UpdatedAt",
                                  VAL: "Asc"
                                }),
                            search: state.search,
                            entityUpdates: state.entityUpdates,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentCategoryId: state.currentCategoryId
                          },
                          _1: (function (param) {
                              param.dispatch("FetchBlogPostCategories");
                            })
                        };
              case "SortByPriorityOrder" :
                  var match$6 = state.status;
                  if (typeof match$6 !== "object" && match$6 === "FetchingBlogPostCategories") {
                    return "NoUpdate";
                  }
                  var match$7 = state.sortBy;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingBlogPostCategories",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: typeof match$7 === "object" && match$7.NAME === "PriorityOrder" && match$7.VAL !== "Desc" ? ({
                                  NAME: "PriorityOrder",
                                  VAL: "Desc"
                                }) : ({
                                  NAME: "PriorityOrder",
                                  VAL: "Asc"
                                }),
                            search: state.search,
                            entityUpdates: state.entityUpdates,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentCategoryId: state.currentCategoryId
                          },
                          _1: (function (param) {
                              param.dispatch("FetchBlogPostCategories");
                            })
                        };
              case "CloseDestroyConfirm" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            entityUpdates: state.entityUpdates,
                            openDestroyConfirm: false,
                            currentCategoryId: undefined
                          }
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SucceedBlogPostCategoriesFetch" :
                  var res = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: res.blogPostCategories
                            },
                            currentPage: res.currentPage,
                            totalPages: res.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            entityUpdates: state.entityUpdates,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentCategoryId: state.currentCategoryId
                          },
                          _1: (function (param) {
                              Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
                                      return prim.scrollTop;
                                    }));
                            })
                        };
              case "UpdatePage" :
                  var nextPage = action._0;
                  var match$8 = state.status;
                  if (typeof match$8 !== "object" && match$8 === "FetchingBlogPostCategories") {
                    return "NoUpdate";
                  }
                  if (state.currentPage !== nextPage) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingBlogPostCategories",
                              currentPage: nextPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              entityUpdates: state.entityUpdates,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentCategoryId: state.currentCategoryId
                            },
                            _1: (function (param) {
                                param.dispatch("FetchBlogPostCategories");
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "UpdateSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: action._0,
                            entityUpdates: state.entityUpdates,
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentCategoryId: state.currentCategoryId
                          },
                          _1: searchBlogPostCategories
                        };
              case "PerformSearch" :
                  var match$9 = state.status;
                  if (typeof match$9 !== "object" && match$9 === "FetchingBlogPostCategories") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.search) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingBlogPostCategories",
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              entityUpdates: state.entityUpdates,
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentCategoryId: state.currentCategoryId
                            },
                            _1: (function (param) {
                                param.dispatch("FetchBlogPostCategories");
                              })
                          };
                  }
              case "UpdateFeatured" :
                  var action$1 = action._1;
                  var blogPostCategoryId = action._0;
                  var match$10 = state.status;
                  var match$11 = Belt_Map.get(state.entityUpdates, blogPostCategoryId);
                  if (typeof match$10 !== "object" || match$11 !== undefined) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: {
                                TAG: "Ready",
                                _0: Belt_Array.map(match$10._0, (function (x) {
                                        if (!Caml_obj.equal(x.id, blogPostCategoryId)) {
                                          return x;
                                        }
                                        var tmp;
                                        tmp = action$1 === "Featured" ? true : false;
                                        return {
                                                id: x.id,
                                                title: x.title,
                                                slug: x.slug,
                                                featured: tmp,
                                                protected: x.protected,
                                                blogPosts: x.blogPosts,
                                                priorityOrder: x.priorityOrder,
                                                createdAt: x.createdAt,
                                                updatedAt: x.updatedAt
                                              };
                                      }))
                              },
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              entityUpdates: Belt_Map.set(state.entityUpdates, blogPostCategoryId, {
                                    TAG: "FeaturedUpdate",
                                    _0: action$1
                                  }),
                              openDestroyConfirm: state.openDestroyConfirm,
                              currentCategoryId: state.currentCategoryId
                            },
                            _1: (function (param) {
                                var dispatch = param.dispatch;
                                if (action$1 === "Featured") {
                                  return $$Promise.wait(Api_BlogPostCategory.feature(blogPostCategoryId), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return dispatch({
                                                              TAG: "SucceedFeaturedUpdate",
                                                              _0: blogPostCategoryId
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "BlogPostCategoriesDashboard",
                                                      subModulePath: /* [] */0,
                                                      value: "default",
                                                      fullPath: "BlogPostCategoriesDashboard.default"
                                                    }, "BlogPostCategoriesDashboard" + "::UpdateStatus::Feature::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailFeaturedUpdate",
                                                      _0: blogPostCategoryId
                                                    });
                                              }));
                                } else {
                                  return $$Promise.wait(Api_BlogPostCategory.unfeature(blogPostCategoryId), (function (x) {
                                                if (x.TAG === "Ok") {
                                                  return dispatch({
                                                              TAG: "SucceedFeaturedUpdate",
                                                              _0: blogPostCategoryId
                                                            });
                                                }
                                                SentryLogger.error1({
                                                      rootModule: "BlogPostCategoriesDashboard",
                                                      subModulePath: /* [] */0,
                                                      value: "default",
                                                      fullPath: "BlogPostCategoriesDashboard.default"
                                                    }, "BlogPostCategoriesDashboard" + "::UpdateStatus::Unfeature::Error", [
                                                      "Error",
                                                      x._0
                                                    ]);
                                                dispatch({
                                                      TAG: "FailFeaturedUpdate",
                                                      _0: blogPostCategoryId
                                                    });
                                              }));
                                }
                              })
                          };
                  }
              case "SucceedFeaturedUpdate" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            entityUpdates: Belt_Map.remove(state.entityUpdates, action._0),
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentCategoryId: state.currentCategoryId
                          }
                        };
              case "FailFeaturedUpdate" :
                  var blogPostCategoryId$1 = action._0;
                  var match$12 = state.status;
                  var match$13 = Belt_Map.get(state.entityUpdates, blogPostCategoryId$1);
                  if (typeof match$12 !== "object") {
                    return "NoUpdate";
                  }
                  if (match$13 === undefined) {
                    return "NoUpdate";
                  }
                  var action$2 = match$13._0;
                  return {
                          TAG: "Update",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: Belt_Array.map(match$12._0, (function (x) {
                                      if (!Caml_obj.equal(x.id, blogPostCategoryId$1)) {
                                        return x;
                                      }
                                      var tmp;
                                      tmp = action$2 === "Featured" ? true : false;
                                      return {
                                              id: x.id,
                                              title: x.title,
                                              slug: x.slug,
                                              featured: tmp,
                                              protected: x.protected,
                                              blogPosts: x.blogPosts,
                                              priorityOrder: x.priorityOrder,
                                              createdAt: x.createdAt,
                                              updatedAt: x.updatedAt
                                            };
                                    }))
                            },
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            entityUpdates: Belt_Map.remove(state.entityUpdates, blogPostCategoryId$1),
                            openDestroyConfirm: state.openDestroyConfirm,
                            currentCategoryId: state.currentCategoryId
                          }
                        };
              case "OpenDestroyConfirm" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            entityUpdates: state.entityUpdates,
                            openDestroyConfirm: true,
                            currentCategoryId: Caml_option.some(action._0)
                          }
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchBlogPostCategories");
        }), []);
  var match$1 = state.sortBy;
  var match$2 = state.sortBy;
  var match$3 = state.sortBy;
  var blogPostCategories = state.status;
  var tmp;
  if (typeof blogPostCategories !== "object") {
    tmp = blogPostCategories === "FetchingBlogPostCategories" ? JsxRuntime.jsx(TableRow.make, {
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Spinner.make, {
                              size: "XL",
                              color: "Teal"
                            }),
                        className: DashboardTableCss.spinner
                      })
                })
          }) : JsxRuntime.jsx(TableRow.make, {
            className: DashboardTableCss.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "Something went wrong during the search."
                })
          });
  } else {
    var blogPostCategories$1 = blogPostCategories._0;
    tmp = blogPostCategories$1.length !== 0 ? Belt_Array.map(blogPostCategories$1, (function (blogPostCategory) {
              var featured = blogPostCategory.featured;
              return JsxRuntime.jsxs(TableRow.make, {
                          children: [
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(Link.make, {
                                              href: "#",
                                              children: blogPostCategory.title
                                            })
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: String(blogPostCategory.blogPosts)
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: String(blogPostCategory.priorityOrder)
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx(Toggle.make, {
                                        on: featured,
                                        size: "MD",
                                        onChange: (function () {
                                            dispatch({
                                                  TAG: "UpdateFeatured",
                                                  _0: blogPostCategory.id,
                                                  _1: featured ? "Unfeatured" : "Featured"
                                                });
                                          })
                                      })
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: blogPostCategory.protected ? "True" : "False"
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: $$Date.Naive.format(blogPostCategory.createdAt, "MM/dd/yyyy")
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: $$Date.Naive.format(blogPostCategory.updatedAt, "MM/dd/yyyy")
                                }),
                            JsxRuntime.jsx(TableCell.make, {
                                  children: JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsxs(Popover.make, {
                                              children: [
                                                JsxRuntime.jsx(Popover.Trigger.make, {
                                                      children: JsxRuntime.jsx(Control.make, {
                                                            children: JsxRuntime.jsx(IconMenu.make, {
                                                                  size: "MD",
                                                                  color: "Teal"
                                                                })
                                                          })
                                                    }),
                                                JsxRuntime.jsxs(Dropdown.Body.make, {
                                                      position: {
                                                        TAG: "Below",
                                                        _0: "RightEdge"
                                                      },
                                                      className: DashboardFilterCss.dropdownBody,
                                                      children: [
                                                        JsxRuntime.jsx(Control.make, {
                                                              className: DashboardFilterCss.dropdownBodyRow,
                                                              children: JsxRuntime.jsx("a", {
                                                                    children: "View",
                                                                    className: DashboardFilterCss.dropdownBodyLink,
                                                                    href: Routes_BlogPost.category(blogPostCategory.slug)
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(Control.make, {
                                                              className: DashboardFilterCss.dropdownBodyRow,
                                                              children: JsxRuntime.jsx("a", {
                                                                    children: "Edit",
                                                                    className: DashboardFilterCss.dropdownBodyLink,
                                                                    href: Routes_BlogPostCategory.Dashboard.edit(blogPostCategory.id)
                                                                  })
                                                            }),
                                                        blogPostCategory.protected ? null : JsxRuntime.jsx(Control.make, {
                                                                className: DashboardIndexCss.dropdownBodyRow,
                                                                children: JsxRuntime.jsx("a", {
                                                                      children: "Delete",
                                                                      className: DashboardIndexCss.dropdownBodyLink,
                                                                      href: "#",
                                                                      onClick: (function (param) {
                                                                          dispatch({
                                                                                TAG: "OpenDestroyConfirm",
                                                                                _0: blogPostCategory.id
                                                                              });
                                                                        })
                                                                    })
                                                              })
                                                      ]
                                                    })
                                              ]
                                            }),
                                        className: DashboardIndexCss.menu
                                      })
                                })
                          ]
                        }, ID.toString(blogPostCategory.id));
            })) : JsxRuntime.jsx(TableRow.make, {
            className: DashboardTableCss.fetchError,
            children: JsxRuntime.jsx(TableCell.make, {
                  colSpan: 7,
                  children: "No blog post categories found."
                })
          });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                state.openDestroyConfirm ? JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Are you sure?",
                                      className: DashboardDeleteConfirmationModalCss.text
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: "Blog posts will be reassigned to the Technology category.",
                                      className: DashboardDeleteConfirmationModalCss.message
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Button.make, {
                                              size: "SM",
                                              color: "Gray",
                                              onClick: (function (param) {
                                                  dispatch("CloseDestroyConfirm");
                                                }),
                                              children: "Cancel"
                                            }),
                                        JsxRuntime.jsx(Button.make, {
                                              size: "SM",
                                              color: "Pink",
                                              onClick: (function (param) {
                                                  var blogPostCategoryId = state.currentCategoryId;
                                                  if (blogPostCategoryId !== undefined) {
                                                    return $$Promise.wait(Api_BlogPostCategory.destroy(Caml_option.valFromOption(blogPostCategoryId)), (function (x) {
                                                                  if (x.TAG === "Ok") {
                                                                    return Url.visit(Routes_BlogPostCategory.Dashboard.index);
                                                                  } else {
                                                                    return SentryLogger.error1({
                                                                                rootModule: "BlogPostCategoriesDashboard",
                                                                                subModulePath: /* [] */0,
                                                                                value: "default",
                                                                                fullPath: "BlogPostCategoriesDashboard.default"
                                                                              }, "DestroyBlogPostCategory::Error", [
                                                                                "Error",
                                                                                x._0
                                                                              ]);
                                                                  }
                                                                }));
                                                  } else {
                                                    return dispatch("CloseDestroyConfirm");
                                                  }
                                                }),
                                              children: "Yes, delete it"
                                            })
                                      ],
                                      className: DashboardDeleteConfirmationModalCss.buttons
                                    })
                              ],
                              className: DashboardDeleteConfirmationModalCss.dialog
                            }),
                        className: DashboardDeleteConfirmationModalCss.overlay,
                        onClick: (function ($$event) {
                            $$event.stopPropagation();
                          })
                      }) : null,
                JsxRuntime.jsx(BlogPostCategoryNavbar.make, {
                      selectedCategory: "BlogPostCategoriesDashboard"
                    }),
                JsxRuntime.jsxs(Container.make, {
                      className: DashboardIndexCss.layout,
                      setRef: Caml_option.some(container),
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(H1.make, {
                                      className: DashboardIndexCss.title,
                                      children: "Blog Post Categories"
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(SearchField.make, {
                                              id: "blog-post-categories-search",
                                              value: state.search,
                                              placeholder: "Search by title, category, or date",
                                              inputClassName: DashboardFilterCss.searchField,
                                              onChange: (function ($$event) {
                                                  dispatch({
                                                        TAG: "UpdateSearchInput",
                                                        _0: $$event.target.value
                                                      });
                                                })
                                            }),
                                        JsxRuntime.jsx(BlogPostCategoriesOptions.make, {})
                                      ],
                                      className: DashboardIndexCss.headerControls
                                    })
                              ],
                              className: DashboardIndexCss.header
                            }),
                        JsxRuntime.jsx("section", {
                              children: JsxRuntime.jsxs(Table.make, {
                                    className: DashboardTableCss.table,
                                    children: [
                                      JsxRuntime.jsx(TableHeader.make, {
                                            children: JsxRuntime.jsxs(TableRow.make, {
                                                  children: [
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: JsxRuntime.jsx(Control.make, {
                                                                onClick: (function (param) {
                                                                    dispatch("SortByTitle");
                                                                  }),
                                                                children: JsxRuntime.jsxs("span", {
                                                                      children: [
                                                                        "Blog Post Category",
                                                                        JsxRuntime.jsx(IconSorting.make, {
                                                                              size: "XXS",
                                                                              direction: typeof match$1 === "object" && match$1.NAME === "Title" ? match$1.VAL : undefined
                                                                            })
                                                                      ],
                                                                      className: DashboardTableCss.controlCell
                                                                    })
                                                              })
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: JsxRuntime.jsx("span", {
                                                                children: "Total Posts",
                                                                className: DashboardTableCss.controlCell
                                                              })
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: JsxRuntime.jsx(Control.make, {
                                                                onClick: (function (param) {
                                                                    dispatch("SortByPriorityOrder");
                                                                  }),
                                                                children: JsxRuntime.jsx("span", {
                                                                      children: "Priority",
                                                                      className: DashboardTableCss.controlCell
                                                                    })
                                                              })
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: JsxRuntime.jsx("span", {
                                                                children: "Featured",
                                                                className: DashboardTableCss.controlCell
                                                              })
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: JsxRuntime.jsx("span", {
                                                                children: "Protected",
                                                                className: DashboardTableCss.controlCell
                                                              })
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: JsxRuntime.jsx(Control.make, {
                                                                onClick: (function (param) {
                                                                    dispatch("SortByCreatedAt");
                                                                  }),
                                                                children: JsxRuntime.jsxs("span", {
                                                                      children: [
                                                                        "Created At",
                                                                        JsxRuntime.jsx(IconSorting.make, {
                                                                              size: "XXS",
                                                                              direction: typeof match$2 === "object" && match$2.NAME === "CreatedAt" ? match$2.VAL : undefined
                                                                            })
                                                                      ],
                                                                      className: DashboardTableCss.controlCell
                                                                    })
                                                              })
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: JsxRuntime.jsx(Control.make, {
                                                                onClick: (function (param) {
                                                                    dispatch("SortByCreatedAt");
                                                                  }),
                                                                children: JsxRuntime.jsxs("span", {
                                                                      children: [
                                                                        "Updated At",
                                                                        JsxRuntime.jsx(IconSorting.make, {
                                                                              size: "XXS",
                                                                              direction: typeof match$3 === "object" && match$3.NAME === "UpdatedAt" ? match$3.VAL : undefined
                                                                            })
                                                                      ],
                                                                      className: DashboardTableCss.controlCell
                                                                    })
                                                              })
                                                        }),
                                                    JsxRuntime.jsx(TableHeaderCell.make, {
                                                          children: ""
                                                        })
                                                  ]
                                                })
                                          }),
                                      JsxRuntime.jsx(TableBody.make, {
                                            children: tmp
                                          })
                                    ]
                                  }),
                              className: DashboardTableCss.tableWrapper
                            }),
                        state.totalPages > 1 ? JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx(Pagination.make, {
                                      currentPage: state.currentPage,
                                      totalPages: state.totalPages,
                                      onPageClick: (function (x) {
                                          dispatch({
                                                TAG: "UpdatePage",
                                                _0: x
                                              });
                                        })
                                    }),
                                className: DashboardTableCss.pagination
                              }) : null
                      ]
                    })
              ]
            });
}

var Css;

var Filters;

var TableCss;

var Modal;

var $$default = BlogPostCategoriesDashboard$default;

export {
  Css ,
  Filters ,
  TableCss ,
  Modal ,
  initialState ,
  $$default as default,
}
/* initialState Not a pure module */
